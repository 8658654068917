import { Component, OnInit, OnDestroy } from '@angular/core';
import { MReportsService } from '../../m-reports.service';

import { SchemesService } from '../../../entities/entities.service';
import { Subscription } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { Entity } from '../../../../plex/entities/entities.model';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { take } from 'rxjs/operators';
import moment from 'moment';

declare const toastr: any;

@Component({
	selector: 'app-current-balances',
	templateUrl: './current-balances.component.html',
	styleUrls: ['./current-balances.component.scss'],
})
export class CurrentBalancesComponent implements OnInit, OnDestroy {
	currentData: any;
	available: any;
	entity: any;
	entityId: string;
	private subscription = new Subscription();
	isLoadingBalances = true;

	isEditBuffer = false;
	loadingBuffer = false;
	isEditOverdraft = false;
	loadingOverDraft = false;

	constructor(private mReportsService: MReportsService, private schemeService: SchemesService, private currencyPipe: CurrencyPipe, private store: Store) {}

	ngOnInit() {
		this.store
			.select(selectEntityId)
			.pipe(take(1))
			.subscribe(id => {
				this.entityId = id;
			});

		this.subscription.add(
			this.schemeService.getEntityDetails(this.entityId).subscribe((entityData: Entity) => {
				this.entity = entityData;
				this.getCurrentBalances();
			})
		);
	}

	formatAmount(amount) {
		if (amount) {
			if (typeof amount === 'string') {
				return this.currencyPipe.transform(parseFloat(amount.replace(/ /g, '')), 'R ').replace(/,/g, ' ');
			} else {
				return this.currencyPipe.transform(parseFloat(amount), 'R ').replace(/,/g, ' ');
			}
		} else {
			return 'R 0.00';
		}
	}

	getCurrentBalances() {
		const prefix = this.entity.isFirefly ? this.entity.prefix : this.entity.whitfieldsPrefix;
		let options: FixMeAny = {};
		options.endDate = moment().format('YYYY-MM-DD');
		options.buffer = this.entity['bufferOverride'] ? this.entity['bufferOverride'] : '';
		options.amountBuffer = this.entity['buffer'] ? this.entity['buffer'] : '';
		options.overdraft = this.entity['overdraft'] ? this.entity['overdraft'] : '0';
		this.mReportsService.getFireflyCurrentBalances(prefix, [8400, 8407, 8405], options).then(async result => {
			if (result) {
				this.currentData = result.accounts.map(item => {
					item.total = this.formatAmount(item.total);
					return item;
				});

				const currentDay = moment();
				//currentDay in string format
				const currentDayStr = currentDay.format('YYYY-MM-DD');

				const endOfDay = moment().endOf('day');
				const endDay = endOfDay.format('YYYY-MM-DD HH:mm');
				let preDay: string;
				const isMonday = currentDay.day() === 1;
				const is1159PM = currentDay.hour() === 23 && currentDay.minute() === 59;
				const lastDayNextMonth = moment().endOf('month').add(1, 'days').format('YYYY-MM-DD');

				if (isMonday && is1159PM) {
					preDay = currentDay.subtract(4, 'days').format('YYYY-MM-DD');
				} else {
					preDay = currentDay.subtract(2, 'days').format('YYYY-MM-DD');
				}

				const paidTotals = await this.mReportsService.fetchSchemePaidPayments(prefix, preDay, endDay, ['paid']);
				const scheduledTotals = await this.mReportsService.fetchSchemePaidPayments(prefix, currentDayStr, lastDayNextMonth, ['pending', 'approved', 'new']);

				if (typeof result.available === 'object') {
					let bufferAmount: FixMeAny = options.amountBuffer;
					if (typeof bufferAmount === 'string') {
						bufferAmount = parseFloat(bufferAmount.replace(/ /g, '').replace('R', ''));
					}
					let overdraftAmount: FixMeAny = result.available.overdraft;

					// Calculate AVAILABLE PAYMENT BALANCE
					let convert8400 = result.accounts.find(acc => acc.account === 8400);
					if (convert8400) {
						if (typeof convert8400.total === 'string') {
							convert8400 = parseFloat(convert8400.total.replace(/ /g, '').replace('R', ''));
						} else {
							convert8400 = convert8400.total;
						}
						convert8400 = convert8400 + +overdraftAmount - +bufferAmount - paidTotals - scheduledTotals;
						result.available.total = this.formatAmount(convert8400);
					}
				} else {
					result.available = {
						total: this.formatAmount(result.available),
					};
				}
				this.available = result.available;
			}
			this.isLoadingBalances = false;
		});
	}

	updateOverdraft(overdraft) {
		this.loadingOverDraft = true;
		this.entity['overdraft'] = overdraft;
		this.schemeService
			.updateEntity(this.entity)
			.then(() => {
				this.isEditOverdraft = false;
				toastr.success('Overdraft successfully updated.');
				this.isEditOverdraft = false;
				this.loadingOverDraft = false;
			})
			.catch(err => {
				toastr.error('There was an error updating Overdraft!');
				this.isEditOverdraft = false;
				this.loadingOverDraft = false;
				console.log(err);
			});
	}

	updateBuffer(bufferAmount) {
		const entity = {};
		this.loadingBuffer = true;
		this.entity['buffer'] = bufferAmount;
		this.entity['bufferOverride'] = bufferAmount != 0 || bufferAmount ? 'yes' : 'no';
		this.entity['id'] = this.entityId;

		this.schemeService
			.updateEntity(this.entity)
			.then(() => {
				toastr.success('Buffer successfully updated.');
				this.isEditBuffer = false;
				this.loadingBuffer = false;
			})
			.catch(err => {
				toastr.error('There was an error updating buffer!');
				this.isEditBuffer = false;
				this.loadingBuffer = false;
				console.log(err);
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
